'use strict';

var app = angular.module('app');

app.component('plan', {
  // defines a two way binding in and out of the component
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  // Pulls in out template
  templateUrl: '/views/plan.html',
  controller: ['ApiService', 'PeriodService', 'FormatterService', function (ApiService, PeriodService, FormatterService) {
    var $ctrl = this;

    $ctrl.pois = {};
    $ctrl.periods = [];

    $ctrl.static_period = false;

    $ctrl.plan = {
        pois: [],
        oggetto_campagna: '',
        conferma: 0,
        opzione: 0,
        quatt_del: null
    };

    $ctrl.$onInit = function () {
      $ctrl.periods = PeriodService.getPeriods(moment().year())
      $ctrl.pois = $ctrl.resolve.data.pois
      if($ctrl.resolve.data.period){
        $ctrl.plan.quatt_del = $ctrl.resolve.data.period;
        $ctrl.static_period = true;
      }
    };

    $ctrl.title = 'Imposta Impianti';
    $ctrl.error = false; 

    $ctrl.closeAlert = function(){
      $ctrl.error = false;
    }
    
    $ctrl.ok = function () {
      $ctrl.error = false;
      
      $ctrl.plan.pois = [];
      for(var i=0; i<$ctrl.pois.length; i++){
          $ctrl.plan.pois.push($ctrl.pois[i].id)
      }

      ApiService.planPoi($ctrl.plan)
        .then(function(response){
          $ctrl.close({$value: response.data});
        })
        .catch(function(errorResponse){
          $ctrl.error = FormatterService.formatError(errorResponse.data.error);
        });
    };

    $ctrl.cancel = function () {
      $ctrl.dismiss({$value: 'cancel'});
    };

  }]
})
