'use strict';

var app = angular.module('app');

app.component('filterpoi', {
  // defines a two way binding in and out of the component
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  // Pulls in out template
  templateUrl: '/views/filterpoi.html',
  controller: ['ApiService', 'FormatterService', 'Upload', function (ApiService, FormatterService, Upload) {
    var $ctrl = this;

    $ctrl.errorFile = false;
    $ctrl.csvfile = false;

    $ctrl.title = 'Filtra Impianti';
    $ctrl.buttonTitle = 'Carica File';
    $ctrl.error = false;

    $ctrl.$onInit = function () {
    };

    $ctrl.closeAlert = function(){
      $ctrl.error = false;
    }

    $ctrl.cancel = function () {
      $ctrl.dismiss({$value: 'cancel'});
    };

    $ctrl.setLoading = function(){
      $ctrl.loading = true;
      $ctrl.buttonTitle = 'Aggiornamento in corso...';    
    };

    $ctrl.setLoaded = function(){
      $ctrl.loading = false;
      $ctrl.buttonTitle = 'Aggiorna Impianti';    
    }

    $ctrl.loading = false;

    $ctrl.uploadFile = function(file) {

      $ctrl.setLoading();

      Upload.upload({
            url: '/api/poi/csvfilter',
            data: {file: file}
        }).then(function (resp) {
            $ctrl.setLoaded();
            $ctrl.close({$value: resp.data.results})
        }, function (resp) {
            $ctrl.setLoaded();       
            $ctrl.error = 'Si è verificato un errore nel processamento del file CSV';
        });
    }
  
  }]
});