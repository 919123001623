'use strict';

var app = angular.module('app');

app.component('poidetail', {
  // defines a two way binding in and out of the component
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  // Pulls in out template
  templateUrl: '/views/poiform.html',
  controller: ['ApiService', 'FormatterService', function (ApiService, FormatterService) {
    var $ctrl = this;

    this.poi = {};

    $ctrl.title = 'Modifica Impianto';
    $ctrl.error = false;

    $ctrl.imageUrl = false;

    $ctrl.$onInit = function () {
        $ctrl.poi = angular.copy($ctrl.resolve.data.item);
        $ctrl.pois = $ctrl.resolve.data.items;

        // get image url
        ApiService.getImage($ctrl.poi.id)
        .then(function(response){
          $ctrl.imageUrl = response.url;
        })
    };

    
    $ctrl.closeAlert = function(){
      $ctrl.error = false;
    }

    $ctrl.poiOverride = false;

    $ctrl.ok = function () {
      ApiService.updatePoi(this.poi.id, this.poi)
        .then(function(response){
          $ctrl.close({$value: response.data.record});
        })
        .catch(function(errorResponse){
          $ctrl.error = FormatterService.formatError(errorResponse.data.error);
        });
    };

    $ctrl.destroy = function() {
      if(confirm('Sei sicuro di voler eliminare l\'impianto?')){
        ApiService.destroyPois([$ctrl.poi.id])
        .then(function(){
          $ctrl.close({$value: false});
        })
        .catch(function(errorResponse){
          $ctrl.error = FormatterService.formatError(errorResponse.data.error);
        });
      }
    }

    $ctrl.cancel = function () {
      $ctrl.dismiss({$value: 'cancel'});
    };

  }]
})
