'use strict';

var app = angular.module('app');

app.component('periodlist', {
  // defines a two way binding in and out of the component
  bindings: {
  },
  // Pulls in out template
  templateUrl: '/views/periodlist.html',
  controller: ['PeriodService', function (PeriodService) {
      this.periods = [];
      this.selectedPeriod = null;

      var ctrl = this;
      this.periods = PeriodService.getPeriods(moment().year());
  }]
})
