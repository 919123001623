(function () {

  angular
    .module('app')
    .service('PeriodService', periodService);

  function periodService () {

    var periodsStore = [];
    var getPeriodFromDate = function(date, progress){
        var period = {};
        var cdate = date.clone();
        period.date = cdate;
        period.start = cdate.toDate();
        period.end = cdate.add(13, 'days').toDate();
        period.index = progress;
        period.id = progress+'_'+cdate.year();
        return period;
    };

    var findFirstPeriod = function(year){
        var start = new Date("1/9/2017");
        var date = moment(start);
        while(date.year()<year){
            date.add(14,'days');
        }
        return date;
    };

    var getPeriods = function(year){
        if(periodsStore.length==0){
            console.log('calculate periods');

            var periods = [];

            // find first monday
            var periodStart = findFirstPeriod(year);
            var nextYear = year+1;
            var nextYearDate = moment(new Date("1/1/"+nextYear));
            var progress = 1;

            while(periodStart.isBefore(nextYearDate)){
                var period = getPeriodFromDate(periodStart, progress);
                periods.push(period);
                periodStart.add(14, 'days');
                progress++;
            }

            periodsStore = periods;
        }
        
        return periodsStore;
    };


    return {
      getPeriods: getPeriods
    };
  }


})();