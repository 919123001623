'use strict';

var app = angular.module('app');

app.component('imageEditor', {
  // defines a two way binding in and out of the component
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  // Pulls in out template
  templateUrl: '/views/imageEditor.html',
  controller: ['ApiService', 'FormatterService', 'Upload', function (ApiService, FormatterService, Upload) {

    var $ctrl = this;

    $ctrl.imageUrl = false;
    $ctrl.poi = false;
    $ctrl.errorFile = false;
    $ctrl.picture = false;

    $ctrl.title = 'Foto Impianto';
    $ctrl.error = false;

    $ctrl.$onInit = function() {
      $ctrl.poi = $ctrl.resolve.poi;
      $ctrl.getImageUrl($ctrl.poi.id);
    };


    $ctrl.closeAlert = function(){
      $ctrl.error = false;
    }

    $ctrl.cancel = function () {
      $ctrl.dismiss({$value: 'cancel'});
    };

    $ctrl.setLoading = function(){
      $ctrl.loading = true;
    };

    $ctrl.setLoaded = function(){
      $ctrl.loading = false;
    }

    $ctrl.ok = function(){
       $ctrl.close({$value: true});
    }

    $ctrl.loading = false;

    $ctrl.uploadPicture = function(file) {
      $ctrl.error = false;
      $ctrl.setLoading();

      Upload.base64DataUrl(file)
        .then(function(url){
          ApiService.uploadPoiImage($ctrl.poi.id, url)
          .then(function(response){
            $ctrl.setLoaded();
            $ctrl.imageUrl = response.data.url+'?ts='+new Date().getTime();
            $ctrl.errorFile = false;
            $ctrl.picture = false;
            // notification
          })
          .catch(function(errorResponse){
            $ctrl.setLoaded();        
            $ctrl.error = FormatterService.formatError(errorResponse.data.error)
          })
        })
    }

    $ctrl.deletePicture = function(){
      if(confirm('Sei sicuro di voler eliminare l\'immagine?')){
        $ctrl.error = false;
        $ctrl.setLoading();
        ApiService.deletePoiImage($ctrl.poi.id)
            .then(function(response){
              $ctrl.setLoaded();
              $ctrl.imageUrl = false;
              // notification
            })
            .catch(function(errorResponse){
              $ctrl.setLoaded();        
              $ctrl.error = FormatterService.formatError(errorResponse.data.error)
            })
      }
    };

    $ctrl.getImageUrl = function(poi_id){
      ApiService.getImage(poi_id)
      .then(function(response){
        $ctrl.imageUrl = response.data.url+'?ts='+new Date().getTime();
      })
      .catch(function(errorResponse){
        $ctrl.imageUrl = false;
      });
    }

  }]
});
