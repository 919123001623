(function () {

  angular
    .module('app')
    .service('FormatterService', formatterService);

  function formatterService () {

    
    function formatError(error){
        // if is string
        if(angular.isString(error)){
            console.log('string');
            console.log(error);            
            return error;
        } else if(angular.isArray(error)){
            console.log('array');
            console.log(error);
            var ret = '';
            for(var i=0; i<error.length; i++){
                ret += '<li>'+error[i]+'</li>';
            }
            return ret
        } else if(angular.isObject(error)){
            console.log('object');
            console.log(error);
            return error.message; // key is the error
        }
    }

    return {
      formatError: formatError
    };
  }


})();