'use strict';

var app = angular.module('app');

app.component('imageViewer', {
  // defines a two way binding in and out of the component
  bindings: {
    poi: '=',
    showPlaceholder: '='
  },
  // Pulls in out template
  templateUrl: '/views/imageViewer.html',
  controller: ['ApiService', 'Lightbox', function (ApiService, Lightbox) {
    var $ctrl = this;

    this.imageUrl = false;
    
    this.$onInit = function() {
      var poi_id = this.poi.id;
      getImageUrl(poi_id);
    };

    var getImageUrl = function(poi_id){
      ApiService.getImage(poi_id)
      .then(function(response){
        $ctrl.imageUrl = response.data.url+'?ts='+new Date().getTime();;
      })
      .catch(function(errorResponse){
        $ctrl.imageUrl = false;
      });
    }

    this.openLightBox = function(){
       var images = [
        {
          'url': $ctrl.imageUrl
        }
      ];

      Lightbox.openModal(images, 0);
    }
  }]
});
