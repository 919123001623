(function () {

  angular
    .module('app')
    .service('LoginService', loginService);

  loginService.$inject = ['$http', 'AuthService'];
  function loginService ($http, AuthService) {

    var login = function(user) {
      return $http.post('/api/login', user)
        .then(function(response) {
          AuthService.saveToken(response.data.token);
        });
    };

    return {
      login : login
    };
  }


})();