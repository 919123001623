'use strict';

var app = angular.module('app');

app.component('login', {
  // defines a two way binding in and out of the component
  bindings: {
  },
  // Pulls in out template
  templateUrl: '/views/login.html',
  controller: ['$state', 'LoginService', function ($state, LoginService) {
    var ctrl = this;
    this.error = false;

    this.closeAlert = function(){
      this.error = false;
    }

    this.login = function(){
      LoginService.login(this.loginData)
      .then(function(){
        // redirect to
        $state.go('index.poilist');
      })
      .catch(function(e){
        // alert
        console.log(e);
        if(e && e.data && e.data.message){
          ctrl.error = e.data.message;
        } else {
          ctrl.error = 'Autenticazione fallita';
        }
      })
    };

    this.loginData = {
      username: 'test',
      password: 'test123'
    };
  }]
});
