'use strict';

var app = angular.module('app');

app.component('geoapp', {
  // defines a two way binding in and out of the component
  bindings: {
  },
  // Pulls in out template
  templateUrl: '/views/geoapp.html',
  controller: function () {
    
  }
});
