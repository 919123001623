(function () {

  angular
    .module('app')
    .factory('AuthHttpInterceptor', authinterceptor);

  authinterceptor.$inject = ['$q', '$location', 'AuthService'];
  function authinterceptor ($q, $location, AuthService) {

   return {
        'request': function (config) {
            if (AuthService.isLoggedIn()) {
                config.headers.authorization = 'Bearer '+ AuthService.getToken()
            }
            return config || $q.when(config);
        },
        responseError: function(rejection) {
            if (rejection.status == 401) {
                //$location.nextAfterLogin = $location.path();
                $location.path('/login');
            }
            return $q.reject(rejection);
        }
    }
  }
})();