(function () {

  angular
    .module('app')
    .service('ApiService', api);

  api.$inject = ['$http'];
  function api ($http) {

    var searchPoi = function(filters){
        return $http.get('/api/poi', {
            params: filters
        });
    };

    var searchNearbyPoi = function(filters){
        return $http.get('/api/poi/nearby', {
            params: filters
        });
    };

    var getConcessionarie = function(){
        return $http.get('/api/poi/concessionarie');
    }

    var periods = function(){
        return $http.get('/api/poi/periods');
    };

    var destroyPois = function(ids){
        return $http.post('/api/poi/delete', {
            ids: ids
        });
    };

    var createPoi = function(data){
        return $http.post('/api/poi', data);
    };

    var updatePoi = function(id, data){
        return $http.put('/api/poi/'+id, data);
    };

    var exportPoiUrl = function(ids){
        return '/api/poi/export?ids='+ids.join(',');
    };

    var exportPoi = function(ids){
      return $http({
          url: '/api/poi/export1',
          method: 'POST',
          responseType: 'arraybuffer',
          data: {
            ids: ids
          },
          headers: {
              'Content-type': 'application/json'
          }
      });
    }

    var filterPoiFromCsv = function(csv){
        return $http.post('/api/poi/csvfilter', {
            file: csv
        });
    };

    var updatePoiFromCsv = function(csv){
        return $http.post('/api/poi/csvupdate', {
            file: csv
        });
    };

    var importPoiCsv = function(csv){
        return $http.post('/api/poi/csvimport', {
            file: csv
        });
    };

    var updatePoiImages = function(images){
        return $http.post('/api/poi/images', {
        });
    };

    var planPoi = function(data){
        return $http.post('/api/poi/plan', data)
    }

    var getImage = function(id){
        return $http.get('/api/poi/image/'+id);
    }

    var uploadPoiImage = function(id, data){
        return $http.post('/api/poi/uploadimage/'+id, {
            image: data
        })
    }

    var deletePoiImage = function(id, data){
        return $http.post('/api/poi/deleteimage/'+id)
    }

    var importPlanCsv = function(period, csv){
        return $http.post('/api/plan/'+period+'/import', {
            file: csv
        });
    };

    var exportPlanUrl = function(period, ids){
        return '/api/plan/export/'+period+'?ids='+ids.join(',');
    };

    var searchNearbyPlan = function(period, filters){
        return $http.get('/api/plan/nearby/'+period, {
            params: filters
        });
    };

    var destroyPlans = function(ids, period){
        return $http.post('/api/plan/delete/'+period, {
            ids: ids
        });
    };

    var updatePlan = function(period, id, data){
        return $http.put('/api/plan/'+period+'/'+id, data);
    };

    var searchPlans = function(period){
        return $http.get('/api/plan/'+period);
    };

    return {
      searchPoi: searchPoi,
      searchNearbyPoi: searchNearbyPoi,
      searchNearbyPlan: searchNearbyPlan,
      periods: periods,
      destroyPois: destroyPois,
      createPoi: createPoi,
      updatePoi: updatePoi,
      planPoi: planPoi,
      uploadPoiImage: uploadPoiImage,
      deletePoiImage: deletePoiImage,
      exportPoiUrl: exportPoiUrl,
      exportPoi: exportPoi,
      filterPoiFromCsv: filterPoiFromCsv,
      updatePoiFromCsv: updatePoiFromCsv,
      importPoiCsv: importPoiCsv,
      updatePoiImages: updatePoiImages,
      importPlanCsv: importPlanCsv,
      exportPlanUrl: exportPlanUrl,
      destroyPlans: destroyPlans,
      updatePlan: updatePlan,
      searchPlans: searchPlans,
      getImage: getImage,
      getConcessionarie: getConcessionarie
    };
  }


})();
