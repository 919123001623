(function () {

  angular
    .module('app')
    .service('AuthService', authentication);

  authentication.$inject = ['$window'];
  function authentication ($window) {

    var saveToken = function (token) {
      $window.localStorage['geomapping-token'] = token;
    };

    var getToken = function () {
      return $window.localStorage['geomapping-token'];
    };

    var isLoggedIn = function() {
      var token = getToken();
      var payload;

      if(token){
        payload = token.split('.')[1];
        payload = $window.atob(payload);
        payload = JSON.parse(payload);

        return payload.exp > Date.now() / 1000;
      } else {
        return false;
      }
    };

    var currentUser = function() {
      if(isLoggedIn()){
        var token = getToken();
        var payload = token.split('.')[1];
        payload = $window.atob(payload);
        payload = JSON.parse(payload);
        return {
          email : payload.email,
          name : payload.name
        };
      }
    };

    var logout = function() {
      $window.localStorage.removeItem('geomapping-token');
    };

    return {
      currentUser : currentUser,
      saveToken : saveToken,
      getToken : getToken,
      isLoggedIn : isLoggedIn,
      logout : logout
    };
  }


})();