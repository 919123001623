'use strict';

var app = angular.module('app');

app.component('menuBar', {
  // defines a two way binding in and out of the component
  bindings: {
    brand: '<'
  },
  // Pulls in out template
  templateUrl: '/views/menuBar.html',
  controller: ['$state', '$uibModal', 'Notification', function ($state, $uibModal, Notification) {

    this.isNavCollapsed = true;

    this.menu = [{
      name: "Nuovo Impianto",
      component: "poicreate",
      is_modal: true,
      class: ''
    }, {
      name: "Importa Impianti",
      component: "importpoi",
      is_modal: true,
      class: ''
    }, {
      name: "Aggiorna Impianti",
      component: "updatepoi",
      is_modal: true,
      class: ''
    }, {
      name: "Importa Pianificazione",
      component: "importplan",
      is_modal: true,
      class: 'primary-item'
    }];

    this.openModal = function (component) {
      var modalInstance = $uibModal.open({
        animation: false,
        size: 'lg',
        component: component,
        resolve: {
        }
      });

      modalInstance.result.then(function (result) {
        if(result.message){
          Notification.info({
            title: 'Esito operazione',
            message: result.message
          });
        } else {
          Notification.info('Operazione conclusa con successo');
        }
        $state.reload(); // refresh state
      }, function () {
        console.log('modal-component dismissed at: ' + new Date());
      });
    };
  }]
});
