'use strict';

var app = angular.module('app');

app.component('planlist', {
  // defines a two way binding in and out of the component
  bindings: {
  },
  // Pulls in out template
  templateUrl: '/views/planlist.html',
  controller: ['ApiService', '$uibModal', '$log', '$timeout', 'NgMap', 'Notification', '$stateParams', function (ApiService, $uibModal, $log, $timeout, NgMap, Notification, $stateParams) {

    var ctrl = this;
    ctrl.period = $stateParams.period;

    NgMap.getMap().then(function(map) {
      ctrl.map = map;

      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "/contextmenu.js";
      document.body.appendChild(s);


      $timeout(function(){
        //	create the ContextMenuOptions object
        var contextMenuOptions={};
        contextMenuOptions.classNames={menu:'context_menu', menuSeparator:'context_menu_separator'};
        
        //	create an array of ContextMenuItem objects
        var menuItems=[];
        menuItems.push({className:'context_menu_item', eventName:'zoom_in_click', label:'Zoom in'});
        menuItems.push({className:'context_menu_item', eventName:'zoom_out_click', label:'Zoom out'});
        //	a menuItem with no properties will be rendered as a separator
        menuItems.push({});
        menuItems.push({className:'context_menu_item', eventName:'center_map_click', label:'Centra mappa qui'});
        menuItems.push({className:'context_menu_item', eventName:'search_map_click', label:'Cerca qui intorno'});        
        contextMenuOptions.menuItems=menuItems;
        
        //	create the ContextMenu object
        var contextMenu=new ContextMenu(map, contextMenuOptions);
        
        //	display the ContextMenu on a Map right click
        google.maps.event.addListener(map, 'rightclick', function(mouseEvent){
          contextMenu.show(mouseEvent.latLng);
        });
        
        //	listen for the ContextMenu 'menu_item_selected' event
        google.maps.event.addListener(contextMenu, 'menu_item_selected', function(latLng, eventName){
          //	latLng is the position of the ContextMenu
          //	eventName is the eventName defined for the clicked ContextMenuItem in the ContextMenuOptions
          switch(eventName){
            case 'zoom_in_click':
              map.setZoom(map.getZoom()+1);
              break;
            case 'zoom_out_click':
              map.setZoom(map.getZoom()-1);
              break;
            case 'center_map_click':
              map.panTo(latLng);
              break;
            case 'search_map_click':
              ctrl.searchNearby(latLng.lat(), latLng.lng(), 1000)
              break;
          }
        });
      }, 1000);
    })
    .catch(function(e){
      console.log(e);
      console.log('error ngmap');
    });

    this.error = false;
    this.gridApi = null;
    this.mapOptions = {
      center: {
         latitude: 41.89193,
         longitude: 12.51133
      },
      zoom: 8
    };

    this.view = 'list';

    this.selectedMarkerModel = {};

    this.selection = [];

    this.contextMenu = {
      coords: {
        
      },
      options: {
        visible: false
      }
    };

    this.changeView = function(viewType){
      this.view = viewType;

      if(this.view=='map'){
//        this.addPositions(this.selection);
        this.addPositions(ctrl.gridApi.selection.getSelectedRows())
      }
    };

    this.addPositions = function(inputArray){
      for(var i=0;i<inputArray.length; i++){
        inputArray[i].position = [inputArray[i].latitude, inputArray[i].longitude];
      }
    };

    this.showInfoWindow = function(e, markerModel){
      markerModel.position = [
        markerModel.latitude,
        markerModel.longitude
      ];

      ctrl.selectedMarkerModel = markerModel;
      ctrl.map.showInfoWindow("poi-iw", 'm'+markerModel.id);
    };

    this.deleteSelected = function(){
      if(confirm('Sei sicuro di voler eliminare le pianificazioni?')){
        var ids = [];
        var selectedRows = this.gridApi.selection.getSelectedRows();
        for(var i=0; i<selectedRows.length; i++){
          ids.push(selectedRows[i]['id']);
        }

        ApiService.destroyPlans(ids, ctrl.period)
        .then(function(){
          getData();
        })
        .catch(function(error){
          console.log(error);
        });
      }
    };

    this.openPlanModal = function (entity) {
      var modalInstance = $uibModal.open({
        animation: false,
        size: 'lg',
        component: 'plan',
        resolve: {
          data: {
            period: ctrl.period,
            pois: ctrl.gridApi.selection.getSelectedRows()
          }
        }
      });

      modalInstance.result.then(function (result) {
        getData();
      }, function () {
        console.log('modal-component dismissed at: ' + new Date());
      });
    };

    this.placePosition = [];
    this.addressChanged = function(){
      ctrl.place = this.getPlace();
      if(ctrl.place.geometry){
        ctrl.map.setCenter(ctrl.place.geometry.location);
        ctrl.placePosition = [ctrl.place.geometry.location.lat(), ctrl.place.geometry.location.lng()];
      }
    };

    this.gridOptions = {
      appScopeProvider: this,
      enableSorting: true,
      enableColumnMenu: false,
      enableFiltering: true,
      enableRowSelection: true,
      multiSelect: true,
      enableSelectAll: false,
      paginationPageSize: 40,
      minRowsToShow: 40,
      virtualizationThreshold: 40,
      paginationPageSizes: [40],
      columnDefs: [
        { name: 'concessionaria', enableColumnMenu: false, enableCellEdit: false, width: '8%' },
        { name: 'cimasa', enableColumnMenu: false, enableCellEdit: false, width: '5%' },
        { name: 'formato', enableColumnMenu: false, enableCellEdit: false, width: '5%' },
        { name: 'indirizzo', enableFiltering: false, enableCellEdit: false, enableColumnMenu: false, width: '*' },
        { name: 'cap', enableFiltering: false, displayName: 'CAP', enableCellEdit: false, enableColumnMenu: false, width: '5%' },
        { name: 'citta', enableFiltering: false, displayName: 'Città', enableCellEdit: false, enableColumnMenu: false, width: '10%' },
        { name: 'municipio', enableFiltering: false, enableColumnMenu: false, width: '7%'},
        { name: 'valore_impianto', enableFiltering: false, displayName: 'Valore', enableCellEdit: false, enableColumnMenu: false, width: '5%'},
        { name: 'oggetto_campagna', displayName: 'Oggetto Campagna', enableColumnMenu: false,  enableCellEdit: true, width: '15%'},
        { name: 'conferma', enableFiltering: false, enableColumnMenu: false, type: 'boolean', enableCellEdit: true, width: '5%', editableCellTemplate: '<div><form name="inputForm"><input type="INPUT_TYPE" ng-class="\'colt\' + col.uid" ui-grid-editor ng-model="MODEL_COL_FIELD" ng-true-value="1" ng-false-value="0" /></form></div>', width: '3%'},
        { name: 'opzione', enableFiltering: false, enableColumnMenu: false, type: 'boolean', enableCellEdit: true, width: '5%', editableCellTemplate: '<div><form name="inputForm"><input type="INPUT_TYPE" ng-class="\'colt\' + col.uid" ui-grid-editor ng-model="MODEL_COL_FIELD" ng-true-value="1" ng-false-value="0" /></form></div>', width: '3%'}
      ],
      onRegisterApi: function(gridApi) {
        ctrl.gridApi = gridApi;
        getData();

        ctrl.gridApi.rowEdit.on.saveRow(null, ctrl.saveRow);
     },
      data: []
    };

    this.saveRow = function( rowEntity ) {
      
      var data = {
        quatt_del: ctrl.period,
        pois: [rowEntity.id],
        oggetto_campagna: rowEntity.oggetto_campagna,
        conferma: rowEntity.conferma,
        opzione: rowEntity.opzione
      };

      var promise = ApiService.planPoi(data);
      
      promise.catch(function(e){
        var error = e.data?e.data.error:'Impossibile salvare la modifica';
        Notification.error(error);
        return promise.reject(e);
      })

      ctrl.gridApi.rowEdit.setSavePromise( rowEntity, promise );  
    };

    this.selecting = false;
    this.selectAll = function(){
      ctrl.selecting = true;
      $timeout(function(){
        if(ctrl.gridApi.selection.getSelectAllState()){
          ctrl.gridApi.selection.clearSelectedRows();
        } else {
          ctrl.gridApi.selection.selectAllRows();
        }
      }, 100);

      $timeout(function(){
        ctrl.selecting = false;
      }, 2500);
    };

    this.loading = false;
    var getData = function(){
      ctrl.loading = true;
      ApiService.searchPlans(ctrl.period)
      .then(function(response){
        ctrl.selection = [];
        ctrl.gridOptions.data = response.data.rows;
        ctrl.gridApi.grid.modifyRows(ctrl.gridOptions.data);
        for(var i=0; i<response.data.rows.length; i++){
          if(response.data.rows[i].oggetto_campagna){
            ctrl.gridApi.selection.selectRow(response.data.rows[i]);
          }
        }
        ctrl.loading = false;
      })
      .catch(function(error){
        ctrl.loading = false;
        console.log(error)
        ctrl.error = 'Errore nel caricamento dei dati';
      })
    };

    this.geoFilter = false;

    this.resetFilter = function(){
      ctrl.geoFilter = false;
      getData();
    }

    this.searchPoint = {};

    this.searchNearby = function(lat, lng, radius){
      this.searchPoint.position = [lat,lng]
      this.searchPoint.radius = radius;
      this.geoSearch(); 
    }

    this.geoSearch = function(){
      ApiService.searchNearbyPlan(ctrl.period, {
        lat: ctrl.searchPoint.position[0],
        lng: ctrl.searchPoint.position[1],
        radius: ctrl.searchPoint.radius*1.00/1000.00
      })
      .then(function(response){
        ctrl.gridOptions.data = response.data.rows;
        ctrl.gridApi.grid.modifyRows(ctrl.gridOptions.data);
        ctrl.gridApi.selection.selectAllRows();

        ctrl.addPositions(ctrl.gridApi.selection.getSelectedRows());
        ctrl.geoFilter = true;
      })
      .catch(function(e){
        console.log(e)
      });
    }

    this.radiusUpdated = function(event){
      if(ctrl.geoFilter){
        console.log(this.radius);
        if(this.radius!=ctrl.searchPoint.radius){
          ctrl.searchPoint.radius = this.radius;
          ctrl.geoSearch();
        }
      }
    };

    this.centerUpdated = function(event){
      if(ctrl.geoFilter){
        console.log(this.center);
        if(this.center.lat()!=ctrl.searchPoint.position[0] || this.center.lng()!=ctrl.searchPoint.position[1]){
          ctrl.searchPoint.position = [this.center.lat(), this.center.lng()]
          ctrl.geoSearch();
        }
      }
    };

    this.exportPlan = function(){
      var ids = [];

      var rows = ctrl.gridApi.selection.getSelectedRows();
      for(var i=0; i<rows.length; i++){
        ids.push(rows[i].id);
      }

      window.open(ApiService.exportPlanUrl(ctrl.period, ids), '_blank');
    };
  }]
});