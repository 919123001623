'use strict';

var app = angular.module('app');

app.component('poiview', {
  // defines a two way binding in and out of the component
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  // Pulls in out template
  templateUrl: '/views/poiview.html',
  controller: ['ApiService', function (ApiService) {
    var $ctrl = this;

    this.poi = {};

    $ctrl.title = 'Dettaglio Impianto';

    $ctrl.imageUrl = false;

    $ctrl.$onInit = function () {
        $ctrl.poi = $ctrl.resolve.data.item;

        // get image url
        ApiService.getImage($ctrl.poi.id)
        .then(function(response){
          $ctrl.imageUrl = response.url;
        })
    };

    $ctrl.ok = function () {
        $ctrl.close({$value: 'ok'});
    };

  }]
})
