'use strict';
var app = angular.module('app', ['ui.router', 'ui.bootstrap', 'ngMap', 'ui.grid',
 'ui.grid.pagination', 'ui.grid.selection', 'ui.grid.resizeColumns', 'ui.grid.autoResize',
 'ui.grid.edit', 'ui.grid.rowEdit', 'snap', 'ngFileUpload', 'ui-notification',
 'bootstrapLightbox', 'ngFileSaver']);

app.config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push('AuthHttpInterceptor');
}]);

app.config(['$qProvider', function ($qProvider) {
    $qProvider.errorOnUnhandledRejections(false);
}]);

app.config(['LightboxProvider', function (LightboxProvider) {
  LightboxProvider.fullScreenMode = true;
}]);

app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', function($stateProvider, $urlRouterProvider, $locationProvider){
  $stateProvider
    .state("index", {
      abstract: true,
      url: "/geo",
      template: '<geoapp></geoapp>',
      authenticate: true
    })
    .state("index.poilist", {
      url: "/poi",
      template: '<poilist></poilist>',
      authenticate: true
    })
    .state("plan", {
      url: "/plan/:period",
      template: '<planlist></planlist>',
      authenticate: true
    })
    .state("login", {
      url: "/login",
      template: '<login></login>',
      authenticate: false
    })
    .state("logout", {
      url: "/logout",
      controller: ['AuthService', '$state', function(AuthService, $state){
        AuthService.logout();
        $state.go('login');
      }],
      authenticate: true
    });
  // Send to login if the URL was not found
  $urlRouterProvider.otherwise("/login");

  //$locationProvider.html5Mode(true);
}]);

app.run(['$rootScope', '$state', 'AuthService', function ($rootScope, $state, AuthService) {
  $rootScope.$on("$stateChangeStart", function(event, toState, toParams, fromState, fromParams){
    if (toState.authenticate && !AuthService.isLoggedIn()){
      // User isn’t authenticated
      $state.transitionTo("login");
      event.preventDefault();
      return;
    }

    if (!toState.authenticate && AuthService.isLoggedIn()){
      // User isn’t authenticated
      $state.transitionTo("index.poilist");
      event.preventDefault();
      return;
    }
  });
}]);
