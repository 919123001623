'use strict';

var app = angular.module('app');

app.component('poicreate', {
  // defines a two way binding in and out of the component
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  // Pulls in out template
  templateUrl: '/views/poiform.html',
  controller: ['ApiService', 'FormatterService', function (ApiService, FormatterService) {
    var $ctrl = this;

    this.poi = {};
    this.concessionarie = [];

    $ctrl.$onInit = function () {
      ApiService.getConcessionarie()
      .then(function(response){
        $ctrl.concessionarie = response.data.items;
      })
    };

    $ctrl.title = 'Nuovo Impianto';
    $ctrl.error = false; 

    $ctrl.closeAlert = function(){
      $ctrl.error = false;
    }
    
    $ctrl.ok = function () {
      $ctrl.error = false;
      var payload = angular.copy(this.poi);
      
      if(payload.concessionaria=='addnew'){
        payload.concessionaria = payload.new_concessionaria;
        delete payload.new_concessionaria;
      }

      ApiService.createPoi(payload)
        .then(function(response){
          $ctrl.close({$value: response.data.record});
        })
        .catch(function(errorResponse){
          $ctrl.error = FormatterService.formatError(errorResponse.data.error);
        });
    };

    $ctrl.cancel = function () {
      $ctrl.dismiss({$value: 'cancel'});
    };

  }]
})
